<template>
<main class="content container">
  <p class="text-center">
    Bestellnummer #<strong>{{ orderId }}</strong> Summe:
    <span class="text-danger">{{ montagePrice ? $filters.currency(totalPrice + montagePrice) : $filters.currency(totalPrice) }}</span>
    <component :is="paymentType.label"></component>
  </p>
</main>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'
import Paypal from './payment/paypal/paypal'
import Mollie from './payment/mollie/mollie'
export default {
  components: {
    Paypal,
    Mollie
  },
  setup () {
    useMeta({
      title: 'Zahlung'
    })
    const store = useStore()

    const orderId = computed(() => {
      return store.getters['checkout/orderId']
    })
    const price = computed(() => {
      return store.getters['checkout/price']
    })
    const totalPrice = computed(() => {
      return price.value.total + price.value.delivery
    })
    const montagePrice = computed(() => {
      return store.getters['checkout/montagePrice']
    })
    const paymentType = computed(() => {
      return store.getters['checkout/paymentType']
    })
    return {
      orderId,
      price,
      totalPrice,
      montagePrice,
      paymentType
    }
  }
}
</script>
